@import url(https://fonts.googleapis.com/css?family=Inter:100,200,300,regular,500,600,700,800,900);
@import url(https://fonts.googleapis.com/css?family=Poppins:100,100italic,200,200italic,300,300italic,regular,italic,500,500italic,600,600italic,700,700italic,800,800italic,900,900italic);
@import url(https://fonts.googleapis.com/css?family=Spectral:200,200italic,300,300italic,regular,italic,500,500italic,600,600italic,700,700italic,800,800italic);
* {
  margin: 0px;
  font-family: inter;
}

p {
  margin-bottom: 0px;
}

.my-container {
  margin: 0px 150px;
}

.image-contain {
  -o-object-fit: contain !important;
  object-fit: contain !important;
}

.image-list {
  position: relative;
}
.image-list::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 0.69%, #292929 100%);
  z-index: 0;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}
