@import url(https://fonts.googleapis.com/css?family=Cairo:200,300,regular,500,600,700,800,900);

.wordcloud * {
  font-family: "cairo";
  font-weight: 600;
}
.wordcloud {
  display: flex;
  flex-direction: column;
  user-select: none;
}
.wordcloud svg {
  margin: 1rem 0;
  padding: 10px;
  cursor: pointer;
}
.wordcloud label {
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  margin-right: 8px;
}
.wordcloud textarea {
  min-height: 100px;
}
